import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { uploadTask, getTasks, getFinalisationTasks } from '../actions/taskActions';
import { GET_FINALISATIONTASKS_RESET } from '../constants/taskConstants'
import Input from '../components/Input'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'

const baseUrl = process.env.REACT_APP_API_URL;

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const FinalisationTask = ({ history }) => {
    const [data, setData] = useState([])
    const [file, setFile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [customerOptions, setCustomerOptions] = useState([])
    const [customer, setCustomer] = useState('')



    const dispatch = useDispatch();
    const taskUpload = useSelector(state => state.taskUpload); // Using the whole taskUpload state

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            setError('Please select a file');
            return;
        }

        if (!customer) {
            setError('Please select a customer');
            return;
        }

        // Dispatch the uploadTask action with the file and customer's ObjectId
        dispatch(uploadTask(file, customer))
            .then(() => {
                // If the upload is successful, show a success toast
                toast.success('File uploaded successfully!');
                // Optionally, reload the window after the toast is shown
                window.location.reload();
            })
            .catch((error) => {
                // Handle any errors that occur during the upload
                console.error('Upload failed:', error);
                // Show an error toast if necessary
                toast.error('File upload failed. Please try again.');
            });
    };

    // Check if taskUpload.loading exists before accessing it
    const loadingg = taskUpload && taskUpload.loading;

    const userLogin = useSelector((state) => state.userLogin)
    const { loadingUserInfo, userInfo } = userLogin

    useEffect(() => {
        // * Check if user info exists
        if (!userInfo) {
            //   history.push('/')
        }
    }, [userInfo])

    // * Check for role
    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { loadingGetRole, getRoleData } = getRoleInfo

    useEffect(() => {
        if (getRoleData) {
            if (!getRoleData.usersView) {
                // history.push('/')
            }
        }
    }, [userInfo, getRoleData, history])

    // * Get Finalisation Tasks
    useEffect(() => {
        dispatch(getFinalisationTasks(currentPage, searchQuery));
        return () => {
            setData([]);
        };
    }, [dispatch, currentPage, searchQuery]);

    const getFinalisationTasksInfo = useSelector((state) => state.getFinalisationTasksInfo)
    const { loadingGetFinalisationTasksInfo, errorGetFinalisationTasksInfo, getFinalisationTasksData } = getFinalisationTasksInfo;

    useEffect(() => {
        dispatch({ type: GET_FINALISATIONTASKS_RESET })
        if (getFinalisationTasksData) {
            setLoading(false)
            setData(Array.isArray(getFinalisationTasksData) ? getFinalisationTasksData : []);
            setTotalPages(getFinalisationTasksData.totalPages || 1);
        } else if (errorGetFinalisationTasksInfo) {
            setLoading(false)
            toast(errorGetFinalisationTasksInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getFinalisationTasksData, errorGetFinalisationTasksInfo])

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when performing a new search
    };


    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
        setCurrentPage(1); // Reset to the first page when selecting a new customer
    };

    const uniqueCustomers = useMemo(() => {
        if (!Array.isArray(data)) return [];
        const customers = data.filter(item => item && item.customerName)
                              .map(item => item.customerName);
        return [...new Set(customers)];
    }, [data]);

    const filteredData = useMemo(() => {
        if (!Array.isArray(data)) return [];
        
        return data.filter(item => {
            if (!item) return false;
            const searchQueryLower = searchQuery.toLowerCase();
            
            const fields = [
                item.taskID?.toString().toLowerCase() ?? "",
                item.name?.toLowerCase() ?? "",
                item.address?.toLowerCase() ?? "",
                item.customerName?.toLowerCase() ?? "",
                item.status?.toLowerCase() ?? "",
                item.loanID?.toLowerCase() ?? "",
                item.assignTo?.toLowerCase() ?? "",
                item.assignBy?.toLowerCase() ?? "",
                item.loanType?.toLowerCase() ?? "",
                item.type?.toLowerCase() ?? ""
            ];
    
            const matchesSearchQuery = fields.some(field => field.includes(searchQueryLower));
            const matchesSelectedCustomer = selectedCustomer ? item.customerName === selectedCustomer : true;
    
            return matchesSearchQuery && matchesSelectedCustomer;
        });
    }, [data, searchQuery, selectedCustomer]);
    


    // * Users Table
    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            cellStyle: {
                minWidth: '150px',
            },
            headerStyle: {
                minWidth: '150px',
            },
            render: (rowData) => (
                <Link
                    className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
                    to={`/tasks/${rowData._id}`}>
                    {rowData.taskID}
                </Link>
            )
        },
        {
            field: 'assignTo',
            title: 'Assign To',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'name',
            title: 'Name',
            cellStyle: {
                minWidth: '200px',
            },
            headerStyle: {
                minWidth: '100px',
            },
        },
        {
            field: 'joinedOn',
            title: 'Allocation on field',
            cellStyle: {
                minWidth: '200px',
            },
        },
        {
            field: 'status',
            title: 'Status',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'customerName',
            title: 'Customer',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'address',
            title: 'Address',
            cellStyle: {
                minWidth: '300px',
            },
        },
        {
            field: 'loanID',
            title: 'Loan ID',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'assignBy',
            title: 'Assign By',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'loanType',
            title: 'Loan Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'type',
            title: 'Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitStartedOn',
            title: 'Visit Start',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitFinishedOn',
            title: 'Visit Finish',
            cellStyle: {
                minWidth: '150px',
            },
        },

    ]

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div id="layoutSidenav">

                <div id="layoutSidenav_content">
                    <main>
                        <div class="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>Finalisation Tasks</h1>
                            <ol class="breadcrumb mb-4">
                                <li class="breadcrumb-item active"></li>
                            </ol>

                            <div class="row mb-4">
                                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between'>
                                        <input
                                            type="text"
                                            placeholder="Global Search..."
                                            className={`block appearance-none w-50 bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm mr-4`}
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />

                                        <select
                                            value={selectedCustomer}
                                            onChange={handleCustomerChange}
                                            className="block appearance-none w-50 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                        >
                                            <option value="">All Customers</option>
                                            {uniqueCustomers.map((customer, index) => (
                                                <option key={index} value={customer}>{customer}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>


                                <MaterialTable
                                    icons={tableIcons}
                                    title={''}
                                    columns={headCells}
                                    data={filteredData}
                                    options={{
                                        exportButton: false,
                                        search: false,
                                        exportAllData: false,
                                        rowStyle: {
                                            height: '5px',
                                            fontSize: 13,
                                        },
                                        paging: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 20, 50],
                                        headerStyle: {
                                            position: 'sticky',
                                            top: '0',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </main>
                </div>

            </div>
        </>
    )
}

export default FinalisationTask