import React, { useEffect, useState, forwardRef, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { getFieldTaskCounts } from '../actions/countActions'
import { FIELD_TASK_COUNT_RESET } from '../constants/countConstants'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const FieldCount = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const dispatch = useDispatch()

    // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      navigate('/')
    }
  }, [userInfo])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.dashboard) {
        toast('Not Authorized', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
        })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData])

    //get Field task Count
    const getFieldTaskCountInfo = useSelector((state) => state.getFieldTaskCountInfo)
    const { loadingFieldTaskCountInfo, errorFieldTaskCountInfo, getFieldTaskCountData } = getFieldTaskCountInfo

    useEffect(() => {
        setIsLoading(true)
        dispatch(getFieldTaskCounts())
    }, [dispatch])

    useEffect(() => {
        if (getFieldTaskCountData) {
            setData(getFieldTaskCountData.fieldExecutives || [])
            setIsLoading(false)
        } else if (errorFieldTaskCountInfo) {
            toast.error(errorFieldTaskCountInfo, {
                hideProgressBar: true,
                autoClose: 2000,
            })
            setIsLoading(false)
        }
    }, [getFieldTaskCountData, errorFieldTaskCountInfo])

    const headCells = useMemo(() => [
        { field: 'name', title: 'Name' },
        { field: 'inProgressCount', title: 'In Progress' },
        { field: 'visitPendingCount', title: 'Visit Pending' },
        { field: 'attendanceStatus', title: 'Attendance' },
        { field: 'totalCount', title: 'Total' },
    ], [])

    const options = useMemo(() => ({
        exportButton: false,
        search: true,
        rowStyle: { height: '5px', fontSize: 13 },
        paging: true,
        pageSize: pageSize,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [10, 20, 50],
        headerStyle: { position: 'sticky', top: '0' },
        showTitle: false,
        footer: true,
        footerStyle: { fontWeight: 'bold' },
    }), [pageSize])

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className='w-full h-full'>
            <h1 className='text-2xl font-semibold'>Field Executive Task Counts</h1>
            <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                <div className='flex justify-between'>
                    <div className='flex gap-5'></div>
                </div>
            </div>
            <MaterialTable
                icons={tableIcons}
                title={''}
                columns={headCells}
                data={data}
                options={options}
                onChangePage={(newPage) => setPage(newPage)}
                onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
            />
        </div>
    )
}

export default React.memo(FieldCount)