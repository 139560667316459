import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnassignedTasks, reassignMultipleTasks } from '../actions/taskActions';
import { getUsers } from '../actions/userActions';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const UnAssignedTask = () => {
  const dispatch = useDispatch();
  const [selectedTasks, setSelectedTasks] = useState({});
  const [bulkUser, setBulkUser] = useState('');
  const [users, setUsers] = useState([]);

  const unassignedTasks = useSelector((state) => state.unassignedTasks);
  const { loadingUnassignedTasksInfo, getUnassignedTasksData, errorGetUnassignedTasksInfo } = unassignedTasks;

  const getUsersInfo = useSelector((state) => state.getUsersInfo);
  const { loading: loadingGetUsersInfo, error: errorGetUsersInfo, getUsersData } = getUsersInfo;

  const reassignMultiple = useSelector((state) => state.reassignMultipleTasks);
  const { loadingAssignedTasksInfo, assignedTasksData, errorAssignedTasksInfo } = reassignMultiple;

  useEffect(() => {
    dispatch(getUnassignedTasks());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (getUsersData) {
      setUsers(getUsersData.users);
    }
  }, [getUsersData]);

  useEffect(() => {
    if (assignedTasksData) {
      toast('Tasks reassigned successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else if (errorAssignedTasksInfo) {
      toast(errorAssignedTasksInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [assignedTasksData, errorAssignedTasksInfo]);

  const handleUserSelect = (event, rowData) => {
    const userId = event.target.value;
    setSelectedTasks(prevSelected => ({
      ...prevSelected,
      [rowData._id]: userId === '' ? true : userId
    }));
  };

  const handleBulkUserSelect = (userId) => {
    setBulkUser(userId);
    setSelectedTasks(prevSelected => {
      const newSelected = { ...prevSelected };
      Object.keys(newSelected).forEach(taskId => {
        if (newSelected[taskId] === true || typeof newSelected[taskId] === 'string') {
          newSelected[taskId] = userId === '' ? true : userId;
        }
      });
      return newSelected;
    });
  };

  const handleReassign = () => {
    const tasksToReassign = Object.entries(selectedTasks)
      .filter(([_, value]) => value && value !== true)
      .map(([taskId, newUserId]) => ({ taskId, newUserId }));

    if (tasksToReassign.length > 0) {
      dispatch(reassignMultipleTasks(tasksToReassign));
    } else {
      alert('Please select tasks and assign users before reassigning.');
    }
  };

  const columns = [
    {
      title: 'Task ID',
      field: 'taskID',
      cellStyle: {
        minWidth: '110px',
      },
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        minWidth: '100px',
      },
      headerStyle: {
        minWidth: '100px',
      },
    },
    {
      title: 'Address',
      field: 'address',
      cellStyle: {
        minWidth: '300px',
      },
    },
    {
      title: 'Customer',
      field: 'customer.name',
      cellStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        minWidth: '150px',
      },
      render: rowData => rowData.customer?.name || 'N/A'
    },
    {
      title: 'Status', 
      field: 'status', 
      cellStyle: {
        minWidth: '150px',
      },
    },
    {
      field: 'allocationRemarks',
      title: 'Allocation Remarks',
      cellStyle: {
        minWidth: '200px',
      },
    },
    {
      field: 'loanID',
      title: 'Loan ID',
      cellStyle: {
        minWidth: '150px',
      },
    },
    {
      field: 'joinedOn',
      title: 'Allocation on field',
      cellStyle: {
        minWidth: '200px',
      },
    },
  ];

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Reassign Multiple Tasks</h2>
      {loadingUnassignedTasksInfo || loadingGetUsersInfo ? (
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      ) : errorGetUnassignedTasksInfo || errorGetUsersInfo ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          {errorGetUnassignedTasksInfo || errorGetUsersInfo}
        </div>
      ) : getUnassignedTasksData && users.length > 0 ? (
        <>
          <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
            <div className='flex justify-between'>
              <div className='flex gap-2'>
                <div className="relative">
                  <select
                    id="bulkAssign"
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    value={bulkUser}
                    onChange={(e) => handleBulkUserSelect(e.target.value)}
                  >
                    <option value=''>Select User</option>
                    {users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name} - {user.identifier}
                      </option>
                    ))}
                  </select>
                  <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                    <svg
                      className='fill-current h-4 w-4'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                    >
                      <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                    </svg>
                  </div>
                </div>
              </div>
              <button
                className="bg-green-500 hover:bg-green-700 text-white text-sm font-semibold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleReassign}
              >
                Reassign Selected Tasks
              </button>
            </div>
          </div>
          <MaterialTable
            icons={tableIcons}
            title="Unassigned Tasks"
            columns={columns}
            data={getUnassignedTasksData}
            options={{
              selection: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              rowStyle: rowData => ({
                backgroundColor: rowData.status === 'Transferred' ? '#fef3c7' : 'inherit'
              })
            }}
            onSelectionChange={(rows) => {
              const newSelectedTasks = {};
              rows.forEach(row => {
                newSelectedTasks[row._id] = selectedTasks[row._id] || true;
              });
              setSelectedTasks(newSelectedTasks);
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default UnAssignedTask;