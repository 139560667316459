import axios from 'axios'

import {
  CURRENT_APP_VERSION_FAIL,
  CURRENT_APP_VERSION_REQUEST,
  CURRENT_APP_VERSION_SUCCESS,
  GET_SETTING_FAIL,
  GET_SETTING_REQUEST,
  GET_SETTING_SUCCESS,
  SETTING_CALLING_DISTANCE_FAIL,
  SETTING_CALLING_DISTANCE_REQUEST,
  SETTING_CALLING_DISTANCE_SUCCESS,
} from '../constants/settingConstants'
import { logout } from './userActions'

const baseUrl = process.env.REACT_APP_API_URL;

export const getSetting = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SETTING_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/settings`, config)

    dispatch({
      type: GET_SETTING_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_SETTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const settingCallingDistance =
  (distanceForCalling) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_CALLING_DISTANCE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${baseUrl}/settings/calling-distance`,
        { distanceForCalling },
        config
      )

      dispatch({
        type: SETTING_CALLING_DISTANCE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: SETTING_CALLING_DISTANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAppVersion =
  (currentAppVersion) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CURRENT_APP_VERSION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${baseUrl}/settings/current-app-version`,
        { currentAppVersion },
        config
      )

      dispatch({
        type: CURRENT_APP_VERSION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: CURRENT_APP_VERSION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }