import React, { useEffect, useState, forwardRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { uploadTask, getTasks, searchTasks } from '../actions/taskActions';
import { GET_TASKS_RESET, SEARCH_TASKS_RESET } from '../constants/taskConstants'
import Input from '../components/Input'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'

const baseUrl = process.env.REACT_APP_API_URL;

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TaskList = ({ history }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState('');
    const [uploadError, setUploadError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [localSearchQuery, setLocalSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [customer, setCustomer] = useState('');

    const taskUpload = useSelector(state => state.taskUpload);
    const { userInfo } = useSelector((state) => state.userLogin);
    const { getRoleData } = useSelector((state) => state.getRoleInfo);
    const { loadingGetTasksInfo, errorGetTasksInfo, getTasksData } = useSelector((state) => state.getTasksInfo);
    const { loadingSearchTasksInfo, errorSearchTasksInfo, searchTasksData } = useSelector((state) => state.searchTasksInfo);

    const loading = loadingGetTasksInfo || loadingSearchTasksInfo;
    const apiError = errorGetTasksInfo || errorSearchTasksInfo;
    const tasksData = searchQuery ? searchTasksData : getTasksData;

    const customerOptions = useMemo(() => {
        return userInfo?.customers?.map((item) => ({
            id: item._id,
            title: item.name,
        })) || [];
    }, [userInfo]);

    const debouncedSearch = useMemo(
        () => debounce((query) => {
            setSearchQuery(query);
            setPage(0);
            if (query) {
                dispatch(searchTasks(query, 0, pageSize));
            } else {
                dispatch(getTasks(0, pageSize));
            }
        }, 300),
        [dispatch, pageSize]
    );

    useEffect(() => {
        debouncedSearch(localSearchQuery);
    }, [localSearchQuery, debouncedSearch]);

    useEffect(() => {
        if (!searchQuery) {
            dispatch(getTasks(page, pageSize));
        } else {
            dispatch(searchTasks(searchQuery, page, pageSize));
        }
    }, [dispatch, page, pageSize, searchQuery]);

    useEffect(() => {
        if (apiError) {
            toast.error(apiError);
        }
    }, [apiError]);

    const handleFileChange = useCallback((e) => {
        setFile(e.target.files[0]);
    }, []);

    const handleUpload = useCallback(async () => {
        if (!file) {
            setUploadError('Please select a file');
            return;
        }

        if (!customer) {
            setUploadError('Please select a customer');
            return;
        }

        setUploadError('');
        const success = await dispatch(uploadTask(file, customer));
        if (success) {
            toast.success('File uploaded and downloaded successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            toast.error('File upload failed. Please try again.');
        }
    }, [file, customer, dispatch]);

    const handleSearchInputChange = useCallback((event) => {
        setLocalSearchQuery(event.target.value);
    }, []);

    const handlePageChange = useCallback((newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const handleDownload = useCallback(() => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/task_data format.xlsx`;
        link.setAttribute('download', 'template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            cellStyle: {
                minWidth: '150px',
            },
            headerStyle: {
                minWidth: '150px',
            },
            render: (rowData) => {
                const baseStyle = 'font-bold text-md p-2 flex justify-center rounded';

                if (rowData.status === 'Waived') {
                    return (
                        <span className={`${baseStyle} text-yellow-800 bg-yellow-100`}>
                            {rowData.taskID}
                        </span>
                    );
                } else {
                    return (
                        <Link
                            className={`${baseStyle} text-green-800 bg-green-100 cursor-pointer`}
                            to={`/tasks/${rowData._id}`}
                        >
                            {rowData.taskID}
                        </Link>
                    );
                }
            }
        },
        {
            field: 'assignTo',
            title: 'Assign To',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'name',
            title: 'Name',
            cellStyle: {
                minWidth: '200px',
            },
            headerStyle: {
                minWidth: '100px',
            },
        },
        {
            field: 'joinedOn',
            title: 'Allocation on field',
            cellStyle: {
                minWidth: '200px',
            },
        },
        {
            field: 'allocationRemarks',
            title: 'Allocation Remarks',
            cellStyle: {
                minWidth: '200px',
            },
        },
        {
            field: 'status',
            title: 'Status',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'customerName',
            title: 'Customer',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'address',
            title: 'Address',
            cellStyle: {
                minWidth: '300px',
            },
        },
        {
            field: 'loanID',
            title: 'Loan ID',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'assignBy',
            title: 'Assign By',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'loanType',
            title: 'Loan Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'type',
            title: 'Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'reassignOn',
            title: 'Reassign On',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitStartedOn',
            title: 'Visit Start',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitFinishedOn',
            title: 'Visit Finish',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'waivedOn',
            title: 'Waived On',
        },
    ];

    if (loading) {
        return <Loading />;
    }

    const totalCount = tasksData?.totalCount || 0;
    const currentPageData = tasksData?.tasks || [];
    const startIndex = page * pageSize + 1;
    const endIndex = Math.min((page + 1) * pageSize, totalCount);

    return (
        <>
            <div id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>New Field Tasks</h1>
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>

                            <div className="row mb-4">
                                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between'>
                                        <input
                                            type="text"
                                            placeholder="Task ID, Status, Allocation Remark & Loan ID"
                                            className="form-control"
                                            value={localSearchQuery}
                                            onChange={handleSearchInputChange}
                                        />

                                        <div className="col-md-5 col-lg-5 text-end">
                                            <button
                                                type="button"
                                                className="btn btn-warning ml-3"
                                                data-toggle="modal"
                                                data-target="#importdata"
                                            >
                                                Import
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-purple ml-3"
                                            >
                                                Export
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success ml-3"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                            >
                                                Add Task
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* import modal */}
                                <div className="modal fade" id="importdata" tabIndex="-1" role="dialog" aria-labelledby="importdataLabel" aria-hidden="true">
                                    <div className="modal-dialog " role="document">
                                        <div className="modal-content w-100">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="importdataLabel">Upload Tasks</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="relative p-6 flex-auto">
                                                <Select
                                                    width='w-full mb-4'
                                                    name='Customer *'
                                                    value={customer}
                                                    onChange={(e) => setCustomer(e.target.value)}
                                                    options={customerOptions}
                                                />
                                                <Input
                                                    type='file'
                                                    width='w-full mb-4'
                                                    name='Upload *'
                                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                    onChange={handleFileChange}
                                                />
                                                {uploadError && <p>{uploadError}</p>}
                                                <div className="col-md-12 col-lg-12 align-item-center">
                                                    <button
                                                        className="btn btn-success w-full"
                                                        onClick={handleUpload}
                                                        disabled={taskUpload.loading}
                                                    >
                                                        {taskUpload.loading ? 'Uploading...' : 'Upload'}
                                                    </button>
                                                    {taskUpload.success && <p>Allocation file uploaded successfully!</p>}
                                                </div>
                                                <div className="col-md-12 col-lg-12 align-item-center mt-4">
                                                    <button
                                                        onClick={handleDownload}
                                                        className="btn btn-info w-full">
                                                        Download Template Excel File
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Custom Table */}
                                <div className="max-w-full p-6 bg-white shadow-md rounded-lg overflow-x-auto">
                                    <table className="table-auto w-full border-collapse border border-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="px-4 py-2 border border-gray-300">Task ID</th>
                                                <th className="px-4 py-2 border border-gray-300">Assign To</th>
                                                <th className="px-4 py-2 border border-gray-300">Name</th>
                                                <th className="px-4 py-2 border border-gray-300">Allocation on field</th>
                                                <th className="px-4 py-2 border border-gray-300">Allocation Remarks</th>
                                                <th className="px-4 py-2 border border-gray-300">Status</th>
                                                <th className="px-4 py-2 border border-gray-300">Customer</th>
                                                <th className="px-4 py-2 border border-gray-300">Address</th>
                                                <th className="px-4 py-2 border border-gray-300">Loan ID</th>
                                                <th className="px-4 py-2 border border-gray-300">Assign By</th>
                                                <th className="px-4 py-2 border border-gray-300">Loan Type</th>
                                                <th className="px-4 py-2 border border-gray-300">Type</th>
                                                <th className="px-4 py-2 border border-gray-300">Reassign On</th>
                                                <th className="px-4 py-2 border border-gray-300">Visit Start</th>
                                                <th className="px-4 py-2 border border-gray-300">Visit Finish</th>
                                                <th className="px-4 py-2 border border-gray-300">Waived On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tasksData && tasksData.length > 0 ? (
                                                tasksData.map((task) => (
                                                    <tr key={task._id} className="hover:bg-gray-50">
                                                        <td className="px-4 py-2 border border-gray-300">
                                                            {task.status === 'Waived' ? (
                                                                <span className="font-bold text-md p-2 flex justify-center rounded text-yellow-800 bg-yellow-100">
                                                                    {task.taskID}
                                                                </span>
                                                            ) : (
                                                                <Link
                                                                    className="font-bold text-md p-2 flex justify-center rounded text-green-800 bg-green-100 cursor-pointer"
                                                                    to={`/tasks/${task._id}`}
                                                                >
                                                                    {task.taskID}
                                                                </Link>
                                                            )}
                                                        </td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.assignTo}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.name}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.joinedOn}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.allocationRemarks}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.status}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.customerName}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.address}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.loanID}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.assignBy}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.loanType}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.type}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.reassignOn}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.visitStartedOn}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.visitFinishedOn}</td>
                                                        <td className="px-4 py-2 border border-gray-300">{task.waivedOn}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="16" className="px-4 py-2 border border-gray-300 text-center">
                                                        {loading ? 'Loading...' : 'No records to display'}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>



                                {/* Pagination */}
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div>
                                        Showing {tasksData && tasksData.totalCount > 0 ? page * pageSize + 1 : 0} - {tasksData ? Math.min((page + 1) * pageSize, tasksData.totalCount) : 0} of {tasksData ? tasksData.totalCount : 0}
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => handlePageChange(page - 1)}
                                            disabled={page === 0}
                                            className="btn btn-sm btn-outline-secondary mr-2"
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() => handlePageChange(page + 1)}
                                            disabled={!tasksData || (page + 1) * pageSize >= tasksData.totalCount}
                                            className="btn btn-sm btn-outline-secondary"
                                        >
                                            Next
                                        </button>
                                    </div>
                                    <div>
                                        <select
                                            value={pageSize}
                                            onChange={handleChangeRowsPerPage}
                                            className="form-control form-control-sm"
                                        >
                                            <option value={10}>10 per page</option>
                                            <option value={20}>20 per page</option>
                                            <option value={50}>50 per page</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default TaskList