import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  ATTENDANCE_COUNT_FAIL,
  ATTENDANCE_COUNT_REQUEST,
  ATTENDANCE_COUNT_SUCCESS,
  ATTENDANCE_COUNT_RESET,
  GET_TASKS_ONE_REQUEST,
  GET_TASKS_ONE_SUCCESS,
  GET_TASKS_ONE_FAIL,
  GET_TASKS_ONE_RESET,
  TASK_COUNT_FAIL,
  TASK_COUNT_REQUEST,
  TASK_COUNT_SUCCESS,
  TASK_COUNT_RESET,
  FIELD_TASK_COUNT_RESET,
  FIELD_TASK_COUNT_FAIL,
  FIELD_TASK_COUNT_REQUEST,
  FIELD_TASK_COUNT_SUCCESS,
  FIELD_TASK_COUNT_HOUR_FAIL,
  FIELD_TASK_COUNT_HOUR_REQUEST,
  FIELD_TASK_COUNT_HOUR_SUCCESS,
  TODAY_TASK_COUNT_FAIL,
  TODAY_TASK_COUNT_REQUEST,
  TODAY_TASK_COUNT_RESET,
  TODAY_TASK_COUNT_SUCCESS,
  TODAY_REGION_TASK_COUNT_FAIL,
  TODAY_REGION_TASK_COUNT_REQUEST,
  TODAY_REGION_TASK_COUNT_RESET,
  TODAY_REGION_TASK_COUNT_SUCCESS,
  TODAY_REGION_STATUS_TASK_COUNT_FAIL,
  TODAY_REGION_STATUS_TASK_COUNT_REQUEST,
  TODAY_REGION_STATUS_TASK_COUNT_RESET,
  TODAY_REGION_STATUS_TASK_COUNT_SUCCESS,
  TODAY_CUSTOMER_TASK_COUNT_SUCCESS,
  TODAY_CUSTOMER_TASK_COUNT_FAIL,
  TODAY_CUSTOMER_TASK_COUNT_REQUEST,
  TODAY_CUSTOMER_TASK_COUNT_RESET
} from '../constants/countConstants'

const baseUrl = process.env.REACT_APP_API_URL;

export const getUserAttendanceCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: ATTENDANCE_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/attendenceCount`, config)

    dispatch({
      type: ATTENDANCE_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: ATTENDANCE_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/taskStatusCount`, config)

    dispatch({
      type: TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFieldTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: FIELD_TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/fieldCount`, config)

    dispatch({
      type: FIELD_TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: FIELD_TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFieldTaskHourCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: FIELD_TASK_COUNT_HOUR_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/fe-task`, config)

    dispatch({
      type: FIELD_TASK_COUNT_HOUR_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: FIELD_TASK_COUNT_HOUR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTodayTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: TODAY_TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/todaytaskStatusCount`, config)

    dispatch({
      type: TODAY_TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: TODAY_TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTodayRegionTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: TODAY_REGION_TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/taskcountbyregion`, config)

    dispatch({
      type: TODAY_REGION_TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: TODAY_REGION_TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTodayRegionStatusTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: TODAY_REGION_STATUS_TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/taskcountbyregionstatus`, config)

    dispatch({
      type: TODAY_REGION_STATUS_TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: TODAY_REGION_STATUS_TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTodayCustomerTaskCounts = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: TODAY_CUSTOMER_TASK_COUNT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/counts/taskcountbycustomer`, config)

    dispatch({
      type: TODAY_CUSTOMER_TASK_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: TODAY_CUSTOMER_TASK_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTasksOneMonth = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TASKS_ONE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/counts/one-month`, config);

    dispatch({
      type: GET_TASKS_ONE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // dispatch(logout());
      return;
    }
    dispatch({
      type: GET_TASKS_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};