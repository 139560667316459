import React, { useEffect, useState, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MaterialTable from 'material-table'
import {
  getUserAttendanceCounts,
  getTodayTaskCounts,
  getTodayRegionTaskCounts,
  getTodayRegionStatusTaskCounts
} from '../actions/countActions'
import {
  ATTENDANCE_COUNT_RESET,
  TODAY_TASK_COUNT_RESET,
  TODAY_REGION_TASK_COUNT_RESET,
  TODAY_REGION_STATUS_TASK_COUNT_RESET
} from '../constants/countConstants'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [attendanceCounts, setAttendanceCounts] = useState({ 
    present: { count: 0, users: [] }, 
    absent: { count: 0, users: [] } 
  });
  const [taskCounts, setTaskCounts] = useState([])
  const [regionTaskCounts, setRegionTaskCounts] = useState([])
  const [regionStatusTaskCounts, setRegionStatusTaskCounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [presentUserModal, setPresentUserModal] = useState(false);
  const [absentUserModal, setAbsentUserModal] = useState(false);
  const fixedStatusOrder = ['Visit Pending', 'In Progress', 'Finalisation Pending', 'Completed'];
  const sortedRegions = regionStatusTaskCounts.sort((a, b) => a.region.localeCompare(b.region));
  const sortedRegionTaskCounts = regionTaskCounts.sort((a, b) => a.region.localeCompare(b.region));

  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      navigate('/')
    }
  }, [userInfo])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.dashboard) {
        toast('Not Authorized', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
        })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData])

  // * Get task Count
  useEffect(() => {
    dispatch(getTodayTaskCounts())
  }, [])

  const getTodayTaskCountInfo = useSelector((state) => state.getTodayTaskCountInfo);
  const { loadingTodayTaskCountInfo, errorTodayTaskCountInfo, getTodayTaskCountData } = getTodayTaskCountInfo;

  useEffect(() => {
    dispatch({ type: TODAY_TASK_COUNT_RESET });
    if (getTodayTaskCountData) {
      setLoading(false);
      setTaskCounts(getTodayTaskCountData);
      console.log(taskCounts)
    } else if (errorTodayTaskCountInfo) {
      setLoading(false);
      toast(errorTodayTaskCountInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [dispatch, getTodayTaskCountData, errorTodayTaskCountInfo]);

  // end task count

  //start region task count

  useEffect(() => {
    dispatch(getTodayRegionTaskCounts())
  }, [])

  const getTodayRegionTaskCountInfo = useSelector((state) => state.getTodayRegionTaskCountInfo)
  const { loadingTodayRegionTaskCountInfo, errorTodayRegionTaskCountInfo, getTodayRegionTaskCountData } = getTodayRegionTaskCountInfo

  useEffect(() => {
    dispatch({ type: TODAY_REGION_TASK_COUNT_RESET })
    if (getTodayRegionTaskCountData) {
      setLoading(false)
      setRegionTaskCounts(getTodayRegionTaskCountData)
      console.log(attendanceCounts)
    } else if (errorTodayRegionTaskCountInfo) {
      setLoading(false)
      toast(errorTodayRegionTaskCountInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [dispatch, getTodayRegionTaskCountData, errorTodayRegionTaskCountInfo])

  // end


  //user active on field count

  useEffect(() => {
    dispatch(getUserAttendanceCounts())
  }, [])

  const getAttendanceCountInfo = useSelector((state) => state.getAttendanceCountInfo)
  const { loadingAttendanceCountInfo, errorAttendanceCountInfo, getAttendanceCountData } = getAttendanceCountInfo

  useEffect(() => {
    dispatch({ type: ATTENDANCE_COUNT_RESET })
    if (getAttendanceCountData) {
      setLoading(false)
      setAttendanceCounts(getAttendanceCountData)
      console.log(attendanceCounts)
    } else if (errorAttendanceCountInfo) {
      setLoading(false)
      toast(errorAttendanceCountInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [dispatch, getAttendanceCountData, errorAttendanceCountInfo])

  // end

  //tasks count by region & status

  useEffect(() => {
    dispatch(getTodayRegionStatusTaskCounts())
  }, [])

  const getTodayRegionStatusTaskCountInfo = useSelector((state) => state.getTodayRegionStatusTaskCountInfo)
  const { loadingTodayRegionStatusTaskCountInfo, errorTodayRegionStatusTaskCountInfo, getTodayRegionStatusTaskCountData } = getTodayRegionStatusTaskCountInfo

  useEffect(() => {
    dispatch({ type: TODAY_REGION_STATUS_TASK_COUNT_RESET })
    if (getTodayRegionStatusTaskCountData) {
      setLoading(false)
      setRegionStatusTaskCounts(getTodayRegionStatusTaskCountData)
      console.log(attendanceCounts)
    } else if (errorTodayRegionStatusTaskCountInfo) {
      setLoading(false)
      toast(errorTodayRegionStatusTaskCountInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [dispatch, getTodayRegionStatusTaskCountData, errorTodayRegionStatusTaskCountInfo])

  // end

  // * Open Present User Modal
  const openPresentUserModal = () => {
    setPresentUserModal(true)
  }

  // * Close Modal
  const closePresentUserModal = () => {
    setPresentUserModal(false)
  }

  // * Open Absent User Modal
  const openAbsentUserModal = () => {
    setAbsentUserModal(true)
  }

  // * Close Modal
  const closeAbsentUserModal = () => {
    setAbsentUserModal(false)
  }

  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'identifier',
      title: 'Identifier',
    }
  ];

  return (
    <>
      <div className='w-100'>
        <h1 className='text-2xl font-semibold'>Dashboard</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          {loadingAttendanceCountInfo ? (
            <p>Loading attendance data...</p>
          ) : (
            <>
              {attendanceCounts ? (
                <div className='w-full'>
                  <h2 className='text-2xl font-semibold mb-4'>Field User</h2>
                  <div className='flex gap-5'>
                    <div className='bg-blue-200 p-4 rounded-md cursor-pointer' onClick={openPresentUserModal}>
                      <h2 className='text-lg font-semibold text-blue-800'>Present</h2>
                      <p className='text-xl text-center text-blue-800'>{attendanceCounts.present.count}</p>
                    </div>
                    <div className='bg-red-200 p-4 rounded-md cursor-pointer' onClick={openAbsentUserModal}>
                      <h2 className='text-lg font-semibold text-red-800'>Absent</h2>
                      <p className='text-xl text-center text-red-800'>{attendanceCounts.absent.count}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No attendance data available.</p>
              )}
            </>
          )}
        </div>
        {presentUserModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-100 my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Active Field Users List</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <MaterialTable
                      icons={tableIcons}
                      title=""
                      columns={headCells}
                      data={attendanceCounts.present.users.map((user) => ({ 
                        identifier: user.identifier, 
                        name: user.name 
                      }))}
                      options={{
                        exportButton: true,
                        search: true,
                        paging: true,
                        pageSize: 5,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [10, 20, 50],
                        headerStyle: {
                          position: 'sticky',
                          top: '0',
                        },
                      }}
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closePresentUserModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {absentUserModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-100 my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Absent Field Users List</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <MaterialTable
                      icons={tableIcons}
                      title=""
                      columns={headCells}
                      data={attendanceCounts.absent.users.map((user) => ({ 
                        identifier: user.identifier, 
                        name: user.name 
                      }))}
                      options={{
                        search: true,
                        paging: true,
                        pageSize: 5,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [10, 20, 50],
                        headerStyle: {
                          position: 'sticky',
                          top: '0',
                        },
                      }}
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAbsentUserModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
      <div className='w-full'>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          {loadingTodayTaskCountInfo ? (
            <p>Loading task data...</p>
          ) : (
            <>
              {taskCounts ? (
                <div className='w-full'>
                  <h2 className='text-2xl font-semibold mb-4'>Today Tasks Status Wise</h2>
                  <div className='grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
                    <div className='bg-yellow-200 p-4 rounded-md'>
                      <h2 className='text-lg font-semibold text-yellow-800 text-center'>Visit Pending</h2>
                      <p className='text-xl text-center text-yellow-800'>
                        {taskCounts.VisitPending ? taskCounts.VisitPending.count : 'N/A'}
                      </p>
                    </div>
                    <div className='bg-orange-200 p-4 rounded-md'>
                      <h2 className='text-lg font-semibold text-orange-800 text-center'>In Progress</h2>
                      <p className='text-xl text-center text-orange-800'>
                        {taskCounts.InProgress ? taskCounts.InProgress.count : 'N/A'}
                      </p>
                    </div>
                    <div className='bg-purple-200 p-4 rounded-md'>
                      <h2 className='text-lg font-semibold text-purple-800 text-center'>Finalisation Pending</h2>
                      <p className='text-xl text-center text-purple-800'>
                        {taskCounts.FinalisationPending ? taskCounts.FinalisationPending.count : 'N/A'}
                      </p>
                    </div>
                    <div className='bg-green-200 p-4 rounded-md'>
                      <h2 className='text-lg font-semibold text-green-800 text-center'>Completed</h2>
                      <p className='text-xl text-center text-green-800'>
                        {taskCounts.Completed ? taskCounts.Completed.count : 'N/A'}
                      </p>
                    </div>
                    <div className='bg-gray-200 p-4 rounded-md'>
                    <h2 className='text-lg font-semibold text-gray-800 text-center'>Unassigned</h2>
                    <p className='text-xl text-center text-gray-800'>
                      {taskCounts.Unassigned ? taskCounts.Unassigned.count : 'N/A'}
                    </p>
                  </div>
                  </div>
                </div>
              ) : (
                <p>No task data available.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className='w-full'>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='w-full'>
            <h2 className='text-2xl font-semibold mb-4'>Today Total Tasks Load Region Wise</h2>
            {regionTaskCounts.length ? (
              <div className='grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {sortedRegionTaskCounts.map(region => (
                  <div key={region.region} className='bg-blue-200 p-4 rounded-md'>
                    <h2 className='text-lg font-semibold text-blue-800 text-center'>{region.region}</h2>
                    <p className='text-xl text-blue-800 text-center'>{region.count}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p>No task data available.</p>
            )}
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='w-full'>
            <h2 className='text-2xl font-semibold mb-4'>Today Tasks Load Region with Status Wise</h2>
            {regionStatusTaskCounts.length ? (
              <div className='grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {sortedRegions.map(region => (
                  <div key={region.region} className='bg-yellow-200 p-4 rounded-md'>
                    <h2 className='text-lg font-semibold text-yellow-800 text-center'>{region.region}</h2>
                    <div className='flex flex-col gap-2'>
                      {fixedStatusOrder.map(status => {
                        // Define a function to return the classes based on the status
                        const getStatusClasses = (status) => {
                          switch (status) {
                            case 'Visit Pending':
                              return 'bg-blue-200 text-blue-800';
                            case 'Finalisation Pending':
                              return 'bg-purple-200 text-purple-800';
                            case 'Completed':
                              return 'bg-green-200 text-green-800';
                            case 'In Progress':
                              return 'bg-orange-200 text-orange-800';
                            default:
                              return 'bg-gray-200 text-gray-800';
                          }
                        };

                        // Get the appropriate classes for the current status
                        const statusClasses = getStatusClasses(status);

                        return (
                          <div key={status} className={`p-2 rounded-md ${statusClasses}`}>
                            <h3 className='text-sm font-semibold text-center'>{status}</h3>
                            <p className='text-lg text-center'>{region.taskCounts[status] ? region.taskCounts[status] : 'N/A'}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No task data available.</p>
            )}
          </div>
        </div>
      </div>

    </>
  )
}

export default Dashboard
