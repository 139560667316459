import React, { useState, useEffect } from 'react'

const EditPincodeForm = ({ pincode, onClose, onSubmit }) => {
    const [pincodeArray, setPincodeArray] = useState('')
    const [region, setRegion] = useState('')
    const [userName, setUserName] = useState('')

    useEffect(() => {
        if (pincode) {
            setPincodeArray(pincode.pincodes)
            setRegion(pincode.region)
            setUserName(pincode.userName || 'Unknown User')
        }
    }, [pincode])

    const handleSubmit = () => {
        onSubmit(pincode._id, pincodeArray.split(',').map(p => p.trim()), region)
    }

    return (
        <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                    {/*content*/}
                    <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                        {/*header*/}
                        <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                            <h3 className='text-lg font-semibold'>Edit Pincode for <b>{userName}</b></h3>
                        </div>
                        {/*body*/}
                        <div className='relative p-6 flex-auto'>
                            <input
                                className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                                type="text"
                                placeholder="Enter pin codes (comma-separated)"
                                value={pincodeArray}
                                onChange={(e) => setPincodeArray(e.target.value)}
                            />
                            <input
                                className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                                type="text"
                                placeholder="Enter Region"
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                            />
                            <p className='text-sm mt-4'>
                                All the fields with * are mandatory
                            </p>
                        </div>
                        {/*footer*/}
                        <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                            <button
                                className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                type='button'
                                onClick={onClose}
                            >
                                Close
                            </button>

                            <button
                                className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                                type='button'
                                onClick={handleSubmit}
                            // disabled={loadingAddPincodeInfo}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
    )
}

export default EditPincodeForm