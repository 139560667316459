import {
    CURRENT_APP_VERSION_FAIL,
    CURRENT_APP_VERSION_REQUEST,
    CURRENT_APP_VERSION_RESET,
    CURRENT_APP_VERSION_SUCCESS,
    GET_SETTING_FAIL,
    GET_SETTING_REQUEST,
    GET_SETTING_RESET,
    GET_SETTING_SUCCESS,
    SETTING_CALLING_DISTANCE_FAIL,
    SETTING_CALLING_DISTANCE_REQUEST,
    SETTING_CALLING_DISTANCE_RESET,
    SETTING_CALLING_DISTANCE_SUCCESS,
  } from '../constants/settingConstants'
  
  export const settingReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_SETTING_REQUEST:
        return { loadingGetSetting: true }
      case GET_SETTING_SUCCESS:
        return { loadingGetSetting: false, getSettingData: action.payload }
      case GET_SETTING_FAIL:
        return { loadingGetSetting: false, errorGetSetting: action.payload }
      case GET_SETTING_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const settingCallingDistanceReducer = (state = {}, action) => {
    switch (action.type) {
      case SETTING_CALLING_DISTANCE_REQUEST:
        return { loadingSettingCallingDistance: true }
      case SETTING_CALLING_DISTANCE_SUCCESS:
        return {
          loadingSettingCallingDistance: false,
          settingCallingDistanceData: action.payload,
        }
      case SETTING_CALLING_DISTANCE_FAIL:
        return {
          loadingSettingCallingDistance: false,
          errorSettingCallingDistance: action.payload,
        }
      case SETTING_CALLING_DISTANCE_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const currentAppVersionReducer = (state = {}, action) => {
    switch (action.type) {
      case CURRENT_APP_VERSION_REQUEST:
        return { loadingCurrentAppVersion: true }
      case CURRENT_APP_VERSION_SUCCESS:
        return {
          loadingCurrentAppVersion: false,
          currentAppVersionData: action.payload,
        }
      case CURRENT_APP_VERSION_FAIL:
        return {
          loadingCurrentAppVersion: false,
          errorCurrentAppVersion: action.payload,
        }
      case CURRENT_APP_VERSION_RESET:
        return {}
      default:
        return state
    }
  }