import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../components/Button'

const Video = () => {
    const navigate = useNavigate();

    const handleCreateRoom = async () => {
        try {
          const response = await fetch('https://pro.golog.tech/room');
          if (response.redirected) {
            window.open(response.url, '_blank');
          }
        } catch (error) {
          console.error('Error creating room:', error);
        }
      };

    return (
        <div className='w-full h-full'>
            <h1 className='text-2xl font-semibold'>Video Call</h1>
            <div className='bg-white shadow-md rounded px-8 py-4 my-4 j'>
                <div className='flex justify-between'>
                    <div className='flex gap-5'></div>
                        <Button
                            custom='py-2'
                            type='button'
                            onClick={handleCreateRoom}
                            text='Create Call'
                        />
                </div>
            </div>
        </div>
    )
}

export default Video