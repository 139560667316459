import {
  TASK_UPLOAD_REQUEST,
  TASK_UPLOAD_SUCCESS,
  TASK_UPLOAD_FAIL,
  COLLECTION_UPLOAD_REQUEST,
  COLLECTION_UPLOAD_SUCCESS,
  COLLECTION_UPLOAD_FAIL,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS_RESET,
  SEARCH_TASKS_REQUEST,
  SEARCH_TASKS_SUCCESS,
  SEARCH_TASKS_FAIL,
  SEARCH_TASKS_RESET,
  GET_BANKTASKS_REQUEST,
  GET_BANKTASKS_SUCCESS,
  GET_BANKTASKS_FAIL,
  GET_BANKTASKS_RESET,
  GET_FINALISATIONTASKS_REQUEST,
  GET_FINALISATIONTASKS_SUCCESS,
  GET_FINALISATIONTASKS_FAIL,
  GET_FINALISATIONTASKS_RESET,
  GET_COMPLETEDTASKS_REQUEST,
  GET_COMPLETEDTASKS_SUCCESS,
  GET_COMPLETEDTASKS_FAIL,
  GET_COMPLETEDTASKS_RESET,
  GET_INPROGRESSTASKS_REQUEST,
  GET_INPROGRESSTASKS_SUCCESS,
  GET_INPROGRESSTASKS_FAIL,
  GET_INPROGRESSTASKS_RESET,
  GET_TASKID_FAIL,
  GET_TASKID_REQUEST,
  GET_TASKID_SUCCESS,
  GET_TASKID_RESET,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID_FAIL,
  GET_TASK_BY_ID_RESET,
  TASK_CHECKED_FAIL,
  TASK_CHECKED_REQUEST,
  TASK_CHECKED_RESET,
  TASK_CHECKED_SUCCESS,
  TASK_FINALISATION_FAIL,
  TASK_FINALISATION_REQUEST,
  TASK_FINALISATION_RESET,
  TASK_FINALISATION_SUCCESS,
  TASK_UPDATE_REMARKS_FAIL,
  TASK_UPDATE_REMARKS_REQUEST,
  TASK_UPDATE_REMARKS_RESET,
  TASK_UPDATE_REMARKS_SUCCESS,
  TASK_UPDATE_SUBMIT_FAIL,
  TASK_UPDATE_SUBMIT_REQUEST,
  TASK_UPDATE_SUBMIT_RESET,
  TASK_UPDATE_SUBMIT_SUCCESS,
  UPDATE_TASK_BY_ID_FAIL,
  UPDATE_TASK_BY_ID_REQUEST,
  UPDATE_TASK_BY_ID_RESET,
  UPDATE_TASK_BY_ID_SUCCESS,
  DIAL_FAIL,
  DIAL_REQUEST,
  DIAL_RESET,
  DIAL_SUCCESS,
  WAIVE_TASK_FAIL,
  WAIVE_TASK_REQUEST,
  WAIVE_TASK_RESET,
  WAIVE_TASK_SUCCESS,
  UNASSIGNED_TASKS_REQUEST,
  UNASSIGNED_TASKS_SUCCESS,
  UNASSIGNED_TASKS_FAIL,
  UNASSIGNED_TASKS_RESET,
  TRANSFERRED_TASKS_REQUEST,
  TRANSFERRED_TASKS_SUCCESS,
  TRANSFERRED_TASKS_FAIL,
  TRANSFERRED_TASKS_RESET,
  GET_FIELDEXECUTIVETASKS_REQUEST,
  GET_FIELDEXECUTIVETASKS_SUCCESS,
  GET_FIELDEXECUTIVETASKS_FAIL,
  GET_FIELDEXECUTIVETASKS_RESET,
  REASSIGN_MULTIPLE_TASKS_REQUEST,
  REASSIGN_MULTIPLE_TASKS_SUCCESS,
  REASSIGN_MULTIPLE_TASKS_FAIL,
  REASSIGN_MULTIPLE_TASKS_RESET
} from '../constants/taskConstants.js';

export const taskUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPLOAD_REQUEST:
      return { loading: true };
    case TASK_UPLOAD_SUCCESS:
      return { loading: false, success: true, message: action.payload.message };
    case TASK_UPLOAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const unassignedTasksReducer = (state = { tasks: [] }, action) => {
  switch (action.type) {
    case UNASSIGNED_TASKS_REQUEST:
      return { loadingUnassignedTasksInfo: true };
    case UNASSIGNED_TASKS_SUCCESS:
      return { loadingUnassignedTasksInfo: false, getUnassignedTasksData: action.payload };
    case UNASSIGNED_TASKS_FAIL:
      return { loadingUnassignedTasksInfo: false, errorGetUnassignedTasksInfo: action.payload };
    case UNASSIGNED_TASKS_RESET:
      return { loadingUnassignedTasksInfo: false, getUnassignedTasksData: null, errorGetUnassignedTasksInfo: null };
    default:
      return state;
  }
}

export const transferredTasksReducer = (state = { tasks: [] }, action) => {
  switch (action.type) {
    case TRANSFERRED_TASKS_REQUEST:
      return { loadingTransferredTasksInfo: true };
    case TRANSFERRED_TASKS_SUCCESS:
      return { loadingTransferredTasksInfo: false, getTransferredTasksData: action.payload };
    case TRANSFERRED_TASKS_FAIL:
      return { loadingTransferredTasksInfo: false, errorGetTransferredTasksInfo: action.payload };
    case TRANSFERRED_TASKS_RESET:
      return { loadingTransferredTasksInfo: false, getTransferredTasksData: null, errorGetTransferredTasksInfo: null };
    default:
      return state;
  }
}

export const reassignMultipleTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case REASSIGN_MULTIPLE_TASKS_REQUEST:
      return { loadingAssignedTasksInfo: true };
    case REASSIGN_MULTIPLE_TASKS_SUCCESS:
      return { loadingAssignedTasksInfo: false, assignedTasksData: action.payload };
    case REASSIGN_MULTIPLE_TASKS_FAIL:
      return { loadingAssignedTasksInfo: false, errorAssignedTasksInfo: action.payload };
    case REASSIGN_MULTIPLE_TASKS_RESET:
      return { loadingAssignedTasksInfo: false, assignedTasksData: null, errorAssignedTasksInfo: null };
    default:
      return state;
  }
}

export const collectionUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_UPLOAD_REQUEST:
      return { Collectionloading: true };
    case COLLECTION_UPLOAD_SUCCESS:
      return { Collectionloading: false, Collectionsuccess: true, message: action.payload.message };
    case COLLECTION_UPLOAD_FAIL:
      return { Collectionloading: false, Collectionerror: action.payload };
    default:
      return state;
  }
};

export const getTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASKS_REQUEST:
      return { loadingGetTasksInfo: true };
    case GET_TASKS_SUCCESS:
      return { 
        loadingGetTasksInfo: false, 
        getTasksData: action.payload.tasks,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount
      };
    case GET_TASKS_FAIL:
      return { loadingGetTasksInfo: false, errorGetTasksInfo: action.payload };
    case GET_TASKS_RESET:
      return { loadingGetTasksInfo: false, getTasksData: null, errorGetTasksInfo: null };
    default:
      return state;
  }
};

export const searchTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TASKS_REQUEST:
      return { loadingSearchTasksInfo: true };
    case SEARCH_TASKS_SUCCESS:
      return { 
        loadingSearchTasksInfo: false, 
        searchTasksData: action.payload.tasks,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount
      };
    case SEARCH_TASKS_FAIL:
      return { loadingSearchTasksInfo: false, errorSearchTasksInfo: action.payload };
    case SEARCH_TASKS_RESET:
      return { loadingSearchTasksInfo: false, searchTasksData: null, errorSearchTasksInfo: null };
    default:
      return state;
  }
};

export const getBankTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BANKTASKS_REQUEST:
      return { loadingGetBankTasksInfo: true };
    case GET_BANKTASKS_SUCCESS:
      return { 
        loadingGetBankTasksInfo: false, 
        getBankTasksData: action.payload.tasks,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount
      };
    case GET_BANKTASKS_FAIL:
      return { loadingGetBankTasksInfo: false, errorGetBankTasksInfo: action.payload };
    case GET_BANKTASKS_RESET:
      return { loadingGetBankTasksInfo: false, getBankTasksData: null, errorGetBankTasksInfo: null };
    default:
      return state;
  }
};

export const getFinalisationTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FINALISATIONTASKS_REQUEST:
      return { loadingGetFinalisationTasksInfo: true };
    case GET_FINALISATIONTASKS_SUCCESS:
      return { loadingGetFinalisationTasksInfo: false, getFinalisationTasksData: action.payload };
    case GET_FINALISATIONTASKS_FAIL:
      return { loadingGetFinalisationTasksInfo: false, errorGetFinalisationTasksInfo: action.payload };
    case GET_FINALISATIONTASKS_RESET:
      return { loadingGetFinalisationTasksInfo: false, getFinalisationTasksData: null, errorGetFinalisationTasksInfo: null };
    default:
      return state;
  }
};

export const getCompletedTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPLETEDTASKS_REQUEST:
      return { loadingGetCompletedTasksInfo: true };
    case GET_COMPLETEDTASKS_SUCCESS:
      return { 
        loadingGetCompletedTasksInfo: false, 
        getCompletedTasksData: {
          data: action.payload.data,
          count: action.payload.count,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        }
      };
    case GET_COMPLETEDTASKS_FAIL:
      return { loadingGetCompletedTasksInfo: false, errorGetCompletedTasksInfo: action.payload };
    case GET_COMPLETEDTASKS_RESET:
      return { loadingGetCompletedTasksInfo: false, getCompletedTasksData: null, errorGetCompletedTasksInfo: null };
    default:
      return state;
  }
};

export const getFieldExecutiveTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FIELDEXECUTIVETASKS_REQUEST:
      return { loadingGetFieldExecutiveTasksInfo: true };
    case GET_FIELDEXECUTIVETASKS_SUCCESS:
      return { 
        loadingGetFieldExecutiveTasksInfo: false, 
        getFieldExecutiveTasksData: action.payload 
      };
    case GET_FIELDEXECUTIVETASKS_FAIL:
      return { 
        loadingGetFieldExecutiveTasksInfo: false, 
        errorGetFieldExecutiveTasksInfo: action.payload 
      };
    case GET_FIELDEXECUTIVETASKS_RESET:
      return { 
        loadingGetFieldExecutiveTasksInfo: false, 
        getFieldExecutiveTasksData: null, 
        errorGetFieldExecutiveTasksInfo: null 
      };
    default:
      return state;
  }
};

export const getInProgressTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INPROGRESSTASKS_REQUEST:
      return { loadingGetInProgressTasksInfo: true };
    case GET_INPROGRESSTASKS_SUCCESS:
      return { loadingGetInProgressTasksInfo: false, getInProgressTasksData: action.payload };
    case GET_INPROGRESSTASKS_FAIL:
      return { loadingGetInProgressTasksInfo: false, errorGetInProgressTasksInfo: action.payload };
    case GET_INPROGRESSTASKS_RESET:
      return { loadingGetInProgressTasksInfo: false, getInProgressTasksData: null, errorGetInProgressTasksInfo: null };
    default:
      return state;
  }
};

export const getTasksIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASKID_REQUEST:
      return { loadingGetTasksIdInfo: true };
    case GET_TASKID_SUCCESS:
      return { loadingGetTasksIdInfo: false, getTasksIdData: action.payload };
    case GET_TASKID_FAIL:
      return { loadingGetTasksIdInfo: false, errorGetTasksIdInfo: action.payload };
    case GET_TASKID_RESET:
      return { loadingGetTasksIdInfo: false, getTasksIdData: null, errorGetTasksIdInfo: null };
    default:
      return state;
  }
};

export const getTaskIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASK_BY_ID_REQUEST:
      return { loadingGetTaskIdInfo: true };
    case GET_TASK_BY_ID_SUCCESS:
      return { loadingGetTaskIdInfo: false, getTaskIdData: action.payload };
    case GET_TASK_BY_ID_FAIL:
      return { loadingGetTaskIdInfo: false, errorGetTaskIdInfo: action.payload };
    case GET_TASK_BY_ID_RESET:
      return { loadingGetTaskIdInfo: false, getTaskIdData: null, errorGetTaskIdInfo: null };
    default:
      return state;
  }
};

export const updateTaskByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TASK_BY_ID_REQUEST:
      return { loadingUpdateTaskByIDInfo: true }
    case UPDATE_TASK_BY_ID_SUCCESS:
      return {
        loadingUpdateTaskByIDInfo: false,
        updateTaskByIDData: action.payload,
      }
    case UPDATE_TASK_BY_ID_FAIL:
      return {
        loadingUpdateTaskByIDInfo: false,
        errorUpdateTaskByIDInfo: action.payload,
      }
    case UPDATE_TASK_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const taskCheckedReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_CHECKED_REQUEST:
      return { loadingTaskCheckedInfo: true }
    case TASK_CHECKED_SUCCESS:
      return {
        loadingTaskCheckedInfo: false,
        taskCheckedData: action.payload,
      }
    case TASK_CHECKED_FAIL:
      return {
        loadingTaskCheckedInfo: false,
        errorTaskCheckedInfo: action.payload,
      }
    case TASK_CHECKED_RESET:
      return {}
    default:
      return state
  }
}

export const taskUpdateSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPDATE_SUBMIT_REQUEST:
      return { loadingUpdateSubmitTask: true }
    case TASK_UPDATE_SUBMIT_SUCCESS:
      return {
        loadingUpdateSubmitTask: false,
        updateSubmitTaskData: action.payload,
      }
    case TASK_UPDATE_SUBMIT_FAIL:
      return {
        loadingUpdateSubmitTask: false,
        errorUpdateSubmitTask: action.payload,
      }
    case TASK_UPDATE_SUBMIT_RESET:
      return {}
    default:
      return state
  }
}

export const taskUpdateRemarksReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPDATE_REMARKS_REQUEST:
      return { loadingUpdateRemarksTask: true }
    case TASK_UPDATE_REMARKS_SUCCESS:
      return {
        loadingUpdateRemarksTask: false,
        updateRemarksTaskData: action.payload,
      }
    case TASK_UPDATE_REMARKS_FAIL:
      return {
        loadingUpdateRemarksTask: false,
        errorUpdateRemarksTask: action.payload,
      }
    case TASK_UPDATE_REMARKS_RESET:
      return {}
    default:
      return state
  }
}

export const taskFinalisationReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_FINALISATION_REQUEST:
      return { loadingTaskFinalisation: true }
    case TASK_FINALISATION_SUCCESS:
      return {
        loadingTaskFinalisation: false,
        taskFinalisationData: action.payload,
      }
    case TASK_FINALISATION_FAIL:
      return {
        loadingTaskFinalisation: false,
        errorTaskFinalisation: action.payload,
      }
    case TASK_FINALISATION_RESET:
      return {}
    default:
      return state
  }
}

export const dialReducer = (state = {}, action) => {
  switch (action.type) {
    case DIAL_REQUEST:
      return { loadingDial: true }
    case DIAL_SUCCESS:
      return {
        loadingDial: false,
        dialData: action.payload,
      }
    case DIAL_FAIL:
      return {
        loadingDial: false,
        errorDial: action.payload,
      }
    case DIAL_RESET:
      return {}
    default:
      return state
  }
}

export const waiveTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case WAIVE_TASK_REQUEST:
      return { loadingWaiveTask: true }
    case WAIVE_TASK_SUCCESS:
      return {
        loadingWaiveTask: false,
        waiveTaskData: action.payload,
      }
    case WAIVE_TASK_FAIL:
      return {
        loadingWaiveTask: false,
        errorWaiveTask: action.payload,
      }
    case WAIVE_TASK_RESET:
      return {}
    default:
      return state
  }
}