import {
  ASSIGN_PINCODE_REQUEST,
  ASSIGN_PINCODE_SUCCESS,
  ASSIGN_PINCODE_FAIL,
  ASSIGN_PINCODE_RESET,
  GET_PINCODE_REQUEST,
  GET_PINCODE_SUCCESS,
  GET_PINCODE_FAIL,
  GET_PINCODE_RESET,
  EDIT_PINCODE_REQUEST,
  EDIT_PINCODE_SUCCESS,
  EDIT_PINCODE_FAIL,
  EDIT_PINCODE_RESET,
} from '../constants/pincodeConstants';

// Reducer for pin code assignment
export const addPincodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_PINCODE_REQUEST:
      return { loadingAddPincodeInfo: true };
    case ASSIGN_PINCODE_SUCCESS:
      return { loadingAddPincodeInfo: false, addPincodeData: true };
    case ASSIGN_PINCODE_FAIL:
      return { loadingAddPincodeInfo: false, errorAddPincodeInfo: action.payload };
    case ASSIGN_PINCODE_RESET:
      return {}
    default:
      return state
  }
};

export const getPincodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PINCODE_REQUEST:
      return { loadingGetPincodeInfo: true }
    case GET_PINCODE_SUCCESS:
      return { loadingGetPincodeInfo: false, getPincodeData: action.payload }
    case GET_PINCODE_FAIL:
      return { loadingGetPincodeInfo: false, errorGetPincodeInfo: action.payload }
    case GET_PINCODE_RESET:
      return {}
    default:
      return state
  }
}

export const editPincodeReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PINCODE_REQUEST:
      return { loadingEditPincodeInfo: true }
    case EDIT_PINCODE_SUCCESS:
      return { loadingEditPincodeInfo: false, editPincodeData: action.payload }
    case EDIT_PINCODE_FAIL:
      return { loadingEditPincodeInfo: false, errorEditPincodeInfo: action.payload }
    case EDIT_PINCODE_RESET:
      return {}
    default:
      return state
  }
}