// pincodeAction.js

import axios from 'axios';
import {
  ASSIGN_PINCODE_REQUEST,
  ASSIGN_PINCODE_SUCCESS,
  ASSIGN_PINCODE_FAIL,
  GET_PINCODE_REQUEST,
  GET_PINCODE_SUCCESS,
  GET_PINCODE_FAIL,
  GET_PINCODE_RESET,
  EDIT_PINCODE_REQUEST,
  EDIT_PINCODE_SUCCESS,
  EDIT_PINCODE_FAIL,
} from '../constants/pincodeConstants';

const baseUrl = process.env.REACT_APP_API_URL;

// Action to assign pin codes to a user
export const assignPincode = (userId, pincodeArray, region) => async (dispatch) => {
  try {
    dispatch({ type: ASSIGN_PINCODE_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // Pass userId along with pincodeArray in the request body
    const { data } = await axios.post(`${baseUrl}/pincode/assign/${userId}`, { userId, pincodeArray, region }, config);

    dispatch({
      type: ASSIGN_PINCODE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ASSIGN_PINCODE_FAIL,
      payload: error.response && error.response.data.error
        ? error.response.data.error
        : error.message
    });
  }
};


export const getPincode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PINCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/pincode`, config)

    dispatch({
      type: GET_PINCODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    // if (error.response.status === 401) {
    //   dispatch(logout())
    //   return
    // }
    dispatch({
      type: GET_PINCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const editPincode = (id, pincodeArray, region) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_PINCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${baseUrl}/pincode/${id}`, { pincodeArray, region }, config)

    dispatch({
      type: EDIT_PINCODE_SUCCESS,
      payload: data,
    })

    // Refresh the pincode list
    dispatch(getPincode())
  } catch (error) {
    dispatch({
      type: EDIT_PINCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}