import React, { useEffect, useState, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
    getTodayCustomerTaskCounts
} from '../actions/countActions'
import {
    TODAY_CUSTOMER_TASK_COUNT_RESET
} from '../constants/countConstants'

const TaskCount = () => {
    const navigate = useNavigate();
    const [customerTaskCounts, setCustomerTaskCounts] = useState([])
    const [loading, setLoading] = useState(true)
    const fixedStatusOrder = ['Visit Pending', 'In Progress', 'Finalisation Pending', 'Completed'];
    const sortedRegions = customerTaskCounts.sort((a, b) => a.customerName.localeCompare(b.customerName));

    const dispatch = useDispatch()

    // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      navigate('/')
    }
  }, [userInfo])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.dashboard) {
        toast('Not Authorized', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
        })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData])

    //start region task count

    useEffect(() => {
        dispatch(getTodayCustomerTaskCounts())
    }, [])

    const getTodayCustomerTaskCountInfo = useSelector((state) => state.getTodayCustomerTaskCountInfo)
    const { loadingTodayCustomerTaskCountInfo, errorTodayCustomerTaskCountInfo, getTodayCustomerTaskCountData } = getTodayCustomerTaskCountInfo

    useEffect(() => {
        dispatch({ type: TODAY_CUSTOMER_TASK_COUNT_RESET })
        if (getTodayCustomerTaskCountData) {
            setLoading(false)
            setCustomerTaskCounts(getTodayCustomerTaskCountData)
        } else if (errorTodayCustomerTaskCountInfo) {
            setLoading(false)
            toast(errorTodayCustomerTaskCountInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [dispatch, getTodayCustomerTaskCountData, errorTodayCustomerTaskCountInfo])

    // end

    return (
        <>
            <div className='w-full'>
                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                    <div className='w-full'>
                        <h2 className='text-2xl font-semibold mb-4'>Today Tasks Load Customer Wise</h2>
                        {customerTaskCounts.length ? (
                            <div className='grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                                {sortedRegions.map(customer => (
                                    <div key={customer.customerName} className='bg-yellow-200 p-4 rounded-md'>
                                        <h2 className='text-lg font-semibold text-yellow-800 text-center'>{customer.customerName}</h2>
                                        <div className='flex flex-col gap-2'>
                                            {fixedStatusOrder.map(status => {
                                                // Define a function to return the classes based on the status
                                                const getStatusClasses = (status) => {
                                                    switch (status) {
                                                        case 'Visit Pending':
                                                            return 'bg-blue-200 text-blue-800';
                                                        case 'Finalisation Pending':
                                                            return 'bg-purple-200 text-purple-800';
                                                        case 'Completed':
                                                            return 'bg-green-200 text-green-800';
                                                        case 'In Progress':
                                                            return 'bg-orange-200 text-orange-800';
                                                        default:
                                                            return 'bg-gray-200 text-gray-800';
                                                    }
                                                };
                                                const statusClasses = getStatusClasses(status);
                                                return (
                                                    <div key={status} className={`p-2 rounded-md ${statusClasses}`}>
                                                        <h3 className='text-sm font-semibold text-center'>{status}</h3>
                                                        <p className='text-lg text-center'>{customer.counts[status] ? customer.counts[status] : 'N/A'}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No task data available.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaskCount
