import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { collectionTask, getTasks } from '../actions/taskActions';
import { GET_TASKS_RESET } from '../constants/taskConstants'

import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TaskList1 = ({ history }) => {
    const [data, setData] = useState([])
    const [file, setFile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch();
    const collectionUpload = useSelector(state => state.collectionUpload); // Using the whole taskUpload state

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            setError('Please select a file');
            return;
        }

        dispatch(collectionTask(file));
    };

    // Check if taskUpload.loading exists before accessing it
    const loadingg = collectionUpload && collectionUpload.loading;

    const userLogin = useSelector((state) => state.userLogin)
    const { loadingUserInfo, userInfo } = userLogin

    useEffect(() => {
        // * Check if user info exists
        if (!userInfo) {
            //   history.push('/')
        }
    }, [userInfo, history])

    // * Check for role
    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { loadingGetRole, getRoleData } = getRoleInfo

    useEffect(() => {
        if (getRoleData) {
            if (!getRoleData.usersView) {
                // history.push('/')
            }
        }
    }, [userInfo, getRoleData, history])

    // * Get Customers
    useEffect(() => {
        dispatch(getTasks())
    }, [])

    const getTasksInfo = useSelector((state) => state.getTasksInfo)
    const { loadingGetTasksInfo, errorGetTasksInfo, getTasksData } = getTasksInfo;

    useEffect(() => {
        dispatch({ type: GET_TASKS_RESET })
        if (getTasksData) {
            console.log(getTasksData)
            setLoading(false)
            setData(getTasksData)
        } else if (errorGetTasksInfo) {
            setLoading(false)
            toast(errorGetTasksInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getTasksData, errorGetTasksInfo])


    

    // * Users Table
    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            cellStyle: {
                minWidth: '100px',
              },
              headerStyle: {
                minWidth: '100px',
              },
        },
        {
            field: 'name',
            title: 'Name',
        },
        {
            field: 'address',
            title: 'Address',
        },
        {
            field: 'customerName',
            title: 'Customer',
        },
        {
            field: 'status',
            title: 'Status',
        },
        {
            field: 'loanID',
            title: 'Loan ID',
        },
        {
            field: 'assignTo',
            title: 'Assign To',
        },
        {
            field: 'assignBy',
            title: 'Assign By',
        },
        {
            field: 'loanType',
            title: 'Loan Type',
        },
        {
            field: 'type',
            title: 'Type',
        },
        {
            field: 'joinedOn',
            title: 'Allocation on field',
        },
    ]

    if (loading) {
        return <Loading />
      }

    return (
        <>
            <div id="layoutSidenav">

                <div id="layoutSidenav_content">
                    <main>
                        <div class="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>New Field Tasks</h1>
                            <ol class="breadcrumb mb-4">
                                <li class="breadcrumb-item active"></li>
                            </ol>

                            <div class="row mb-4">
                                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between'>
                                        <input
                                            type="text"
                                            placeholder="Global Search..."
                                            className="form-control"
                                        />

                                        <div className="col-md-4 col-lg-4 text-end">
                                            <button
                                                type="button"
                                                className="btn btn-warning ml-3"
                                                data-toggle="modal"
                                                data-target="#importdata"
                                            >
                                                Import
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-purple ml-3"
                                            >
                                                Export
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success ml-3"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                            >
                                                Add Task
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                {/* import */}
                                <div className="modal fade" id="importdata" tabIndex="-1" role="dialog" aria-labelledby="importdataLabel" aria-hidden="true">
                                    <div className="modal-dialog " role="document">
                                        <div className="modal-content w-100">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="importdataLabel">Upload Tasks</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div className="row p-5">
                                                <div className="col-md-6 col-lg-6">
                                                    <input
                                                        type="file"
                                                        name="excelFile"
                                                        accept=".xlsx"
                                                        onChange={handleFileChange}
                                                    />
                                                    {error && <p>{error}</p>}
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={handleUpload}
                                                        disabled={loadingg}
                                                    >
                                                        {loadingg ? 'Uploading...' : 'Upload'}
                                                    </button>
                                                    {collectionUpload.success && <p>Allocation file uploaded successfully!</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={''}
                                    columns={headCells}
                                    data={data}
                                    options={{
                                        exportButton: false,
                                        search: false,
                                        exportAllData: false,
                                        rowStyle: {
                                            height: '5px',
                                            fontSize: 13,
                                        },
                                        paging: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 20, 50],
                                        headerStyle: {
                                            position: 'sticky',
                                            top: '0',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </main>
                </div>

            </div>
        </>
    )
}

export default TaskList1