import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'

import Input from '../components/Input'
import Button from '../components/Button'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { rejoinUser, resignUsers } from '../actions/userActions'
import {
  RESIGN_USERS_RESET,
  RESIGN_USER_RESET,
} from '../constants/userConstants'
import { Link, useNavigate } from 'react-router-dom'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const ResignedUsers = ({ history }) => {
    const navigate = useNavigate();
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [openModal, setOpenModal] = useState(false)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
        navigate('/')
    }
  }, [userInfo])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.resignedUsers) {
        toast('Not Authorized', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
      })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData])

  useEffect(() => {
    setLoading(true)
    dispatch(resignUsers())
  }, [])

  // * Get Users
  const resignUsersInfo = useSelector((state) => state.resignUsersInfo)
  const { loadingResignUsers, errorResignUsers, resignUsersData } =
    resignUsersInfo

  useEffect(() => {
    dispatch({ type: RESIGN_USERS_RESET })
    if (resignUsersData) {
      setLoading(false)
      setData(resignUsersData)
    } else if (errorResignUsers) {
      setLoading(false)
      toast(errorResignUsers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [resignUsersData, errorResignUsers])

  // * Users Table
  const headCells = [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => (
        <div
          className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
          onClick={() => rejoin(rowData)}
        >
          <p>{rowData.name}</p>
        </div>
      ),
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'identifier',
      title: 'Identifier',
    },
    {
      field: 'role.name',
      title: 'Role',
    },
    {
      field: 'joinedOn',
      title: 'Joined On',
    },
    {
      field: 'resignedOn',
      title: 'Resigned On',
    },
  ]

  // * Open update task modal
  const rejoin = (item) => {
    setOpenModal(true)
    setId(item._id)
  }

  const closeRejoinModal = () => {
    setOpenModal(false)
    setId('')
  }

  const rejoinHandler = () => {
    dispatch(rejoinUser(id))
  }

  const rejoinUserInfo = useSelector((state) => state.rejoinUserInfo)
  const { loadingRejoinUser, errorRejoinUser, rejoinUserData } = rejoinUserInfo

  useEffect(() => {
    dispatch({ type: RESIGN_USER_RESET })
    if (rejoinUserData) {
      toast(rejoinUserData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      closeRejoinModal()
      setTimeout(() => {
        dispatch(resignUsers())
      }, 1000)
    } else if (errorRejoinUser) {
      closeRejoinModal()
      toast(errorRejoinUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [rejoinUserData, errorRejoinUser])

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold mb-4'>Resigned Users</h1>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: false,
          search: true,
          exportAllData: false,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
      />
      {openModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Rejoin User</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <p className='text-sm'>
                    Are you sure, you want to rejoin this user?
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeRejoinModal}
                  >
                    No
                  </button>

                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={rejoinHandler}
                    disabled={loadingRejoinUser}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default ResignedUsers