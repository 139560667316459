export const ASSIGN_PINCODE_REQUEST = 'ASSIGN_PINCODE_REQUEST';
export const ASSIGN_PINCODE_SUCCESS = 'ASSIGN_PINCODE_SUCCESS';
export const ASSIGN_PINCODE_FAIL = 'ASSIGN_PINCODE_FAIL';
export const ASSIGN_PINCODE_RESET = 'ASSIGN_PINCODE_FAIL';

export const GET_PINCODE_REQUEST = 'GET_PINCODE_REQUEST'
export const GET_PINCODE_SUCCESS = 'GET_PINCODE_SUCCESS'
export const GET_PINCODE_FAIL = 'GET_PINCODE_FAIL'
export const GET_PINCODE_RESET = 'GET_PINCODE_RESET'

export const EDIT_PINCODE_REQUEST = 'EDIT_PINCODE_REQUEST';
export const EDIT_PINCODE_SUCCESS = 'EDIT_PINCODE_SUCCESS';
export const EDIT_PINCODE_FAIL = 'EDIT_PINCODE_FAIL';
export const EDIT_PINCODE_RESET = 'EDIT_PINCODE_RESET';