export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST'
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS'
export const GET_SETTING_FAIL = 'GET_SETTING_FAIL'
export const GET_SETTING_RESET = 'GET_SETTING_RESET'

export const SETTING_CALLING_DISTANCE_REQUEST =
  'SETTING_CALLING_DISTANCE_REQUEST'
export const SETTING_CALLING_DISTANCE_SUCCESS =
  'SETTING_CALLING_DISTANCE_SUCCESS'
export const SETTING_CALLING_DISTANCE_FAIL = 'SETTING_CALLING_DISTANCE_FAIL'
export const SETTING_CALLING_DISTANCE_RESET = 'SETTING_CALLING_DISTANCE_RESET'

export const CURRENT_APP_VERSION_REQUEST = 'CURRENT_APP_VERSION_REQUEST'
export const CURRENT_APP_VERSION_SUCCESS = 'CURRENT_APP_VERSION_SUCCESS'
export const CURRENT_APP_VERSION_FAIL = 'CURRENT_APP_VERSION_FAIL'
export const CURRENT_APP_VERSION_RESET = 'CURRENT_APP_VERSION_RESET'