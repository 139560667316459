import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { getFieldExecutiveTasks, resetFieldExecutiveTasks } from '../actions/taskActions';
import { GET_FIELDEXECUTIVETASKS_RESET } from '../constants/taskConstants'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const FieldExecutiveTasksScreen = () => {
    const dispatch = useDispatch();
    const [selectedExecutive, setSelectedExecutive] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [statusFilter, setStatusFilter] = useState('');

    const {
        loadingGetFieldExecutiveTasksInfo,
        errorGetFieldExecutiveTasksInfo,
        getFieldExecutiveTasksData
    } = useSelector((state) => state.fieldExecutiveTasks);

    useEffect(() => {
        dispatch(getFieldExecutiveTasks(selectedExecutive, page + 1, pageSize, searchQuery, statusFilter));

        return () => {
            dispatch({ type: GET_FIELDEXECUTIVETASKS_RESET });
            dispatch(resetFieldExecutiveTasks());
        };
    }, [dispatch, selectedExecutive, page, pageSize, searchQuery, statusFilter]);

    useEffect(() => {
        if (getFieldExecutiveTasksData) {
            toast('Tasks loaded successfully', {
                type: 'success',
                hideProgressBar: true,
                autoClose: 2000,
            });
        } else if (errorGetFieldExecutiveTasksInfo) {
            toast(errorGetFieldExecutiveTasksInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            });
        }
    }, [getFieldExecutiveTasksData, errorGetFieldExecutiveTasksInfo]);

    const handleExecutiveChange = (e) => {
        setSelectedExecutive(e.target.value);
        setPage(0);
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        setPage(0);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
        setPage(0);
    };

    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            // render: (rowData) => (
            //     <Link
            //         className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
            //         to={`/tasks/${rowData._id}`}>
            //         {rowData.taskID}
            //     </Link>
            // ),
            // cellStyle: { minWidth: '150px' },
            // headerStyle: { minWidth: '150px' },
        },
        { field: 'assignTo.name', title: 'Assign To', cellStyle: { minWidth: '150px' } },
        { field: 'name', title: 'Name', cellStyle: { minWidth: '200px' } },
        { field: 'joinedOn', title: 'Allocation on field', cellStyle: { minWidth: '200px' } },
        { field: 'status', title: 'Status', cellStyle: { minWidth: '150px' } },
        { field: 'customer.name', title: 'Customer', cellStyle: { minWidth: '150px' } },
        { field: 'address', title: 'Address', cellStyle: { minWidth: '300px' } },
        { field: 'loanID', title: 'Loan ID', cellStyle: { minWidth: '150px' } },
        { field: 'assignBy', title: 'Assign By', cellStyle: { minWidth: '150px' } },
        { field: 'loanType', title: 'Loan Type', cellStyle: { minWidth: '150px' } },
        { field: 'type', title: 'Type', cellStyle: { minWidth: '150px' } },
    ];

    if (loadingGetFieldExecutiveTasksInfo) {
        return <Loading />;
    }

    return (
        <div id="layoutSidenav">
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">
                        <h1 className='text-2xl font-semibold'>Field Executive Tasks</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item active"></li>
                        </ol>

                        <div className="row mb-4">
                            <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                <div className='flex justify-between'>
                                    {/* <input
                                        type="text"
                                        placeholder="Global Search..."
                                        className="block appearance-none w-50 bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm mr-4"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                    /> */}
                                    <div className='relative w-50 mr-4'>
                                        <select
                                            value={selectedExecutive}
                                            onChange={handleExecutiveChange}
                                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                        >
                                            <option value="">All Field Executives</option>
                                            {getFieldExecutiveTasksData?.fieldExecutives?.map((executive) => (
                                                <option key={executive._id} value={executive._id}>{executive.name}</option>
                                            ))}
                                        </select>
                                        <div class='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                                            <svg
                                                class='fill-current h-4 w-4'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 20 20'
                                            >
                                                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='relative w-50'>
                                        <select
                                            value={statusFilter}
                                            onChange={handleStatusFilterChange}
                                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                        >
                                            <option value="">All Statuses</option>
                                            <option value="Visit Pending">Visit Pending</option>
                                            <option value="In Progress">In Progress</option>
                                        </select>
                                        <div class='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                                            <svg
                                                class='fill-current h-4 w-4'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 20 20'
                                            >
                                                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <MaterialTable
                                icons={tableIcons}
                                title=""
                                columns={headCells}
                                data={getFieldExecutiveTasksData?.tasks || []}
                                options={{
                                    exportButton: false,
                                    search: true,
                                    exportAllData: false,
                                    rowStyle: {
                                        height: '5px',
                                        fontSize: 13,
                                    },
                                    paging: true,
                                    pageSize: pageSize,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 20, 50],
                                    headerStyle: {
                                        position: 'sticky',
                                        top: '0',
                                    },
                                }}
                                onChangePage={(page) => setPage(page)}
                                onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
                                page={page}
                                totalCount={getFieldExecutiveTasksData?.totalTasks || 0}
                            />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default FieldExecutiveTasksScreen;