import React, { useEffect, useState, forwardRef, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { getBankTasks } from '../actions/taskActions'
import { GET_BANKTASKS_RESET } from '../constants/taskConstants'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const baseUrl = process.env.REACT_APP_API_URL;

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const BankTask = ({ history }) => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const getBankTasksInfo = useSelector((state) => state.getBankTasksInfo)
    const { errorGetBankTasksInfo, getBankTasksData } = getBankTasksInfo

    useEffect(() => {
        if (!userInfo) {
            // history.push('/')
        } else {
            // Load all data when component mounts
            dispatch(getBankTasks())
        }
    }, [userInfo, history, dispatch])

    // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.viewAttendance) {
        toast('Not Authorized', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
        })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData, history])

    useEffect(() => {
        if (getBankTasksData) {
            setAllData(getBankTasksData)
            setLoading(false)
        } else if (errorGetBankTasksInfo) {
            toast(errorGetBankTasksInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
            setLoading(false)
        }
    }, [getBankTasksData, errorGetBankTasksInfo])

    const handleSearchInputChange = useCallback((event) => {
        setSearchQuery(event.target.value)
        setCurrentPage(1)
    }, [])

    const filteredData = useMemo(() => {
        if (!allData || !searchQuery) return []
        return allData.filter(item => {
            const searchQueryLower = searchQuery.toLowerCase()
            return item.taskID?.toString().toLowerCase().includes(searchQueryLower) ||
                   item.loanID?.toLowerCase().includes(searchQueryLower)
        })
    }, [allData, searchQuery])

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * 10
        const endIndex = startIndex + 10
        return filteredData.slice(startIndex, endIndex)
    }, [filteredData, currentPage])

    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            cellStyle: { minWidth: '150px' },
            headerStyle: { minWidth: '150px' },
            render: (rowData) => (
                <Link
                    className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
                    to={`/tasks/${rowData._id}`}
                >
                    {rowData.taskID}
                </Link>
            )
        },
        { field: 'assignTo', title: 'Assign To', cellStyle: { minWidth: '150px' } },
        { field: 'name', title: 'Name', cellStyle: { minWidth: '200px' }, headerStyle: { minWidth: '100px' } },
        { field: 'joinedOn', title: 'Allocation on field', cellStyle: { minWidth: '200px' } },
        { field: 'status', title: 'Status', cellStyle: { minWidth: '150px' } },
        { field: 'customerName', title: 'Customer', cellStyle: { minWidth: '150px' } },
        { field: 'address', title: 'Address', cellStyle: { minWidth: '300px' } },
        { field: 'loanID', title: 'Loan ID', cellStyle: { minWidth: '150px' } },
        { field: 'assignBy', title: 'Assign By', cellStyle: { minWidth: '150px' } },
        { field: 'loanType', title: 'Loan Type', cellStyle: { minWidth: '150px' } },
        { field: 'type', title: 'Type', cellStyle: { minWidth: '150px' } },
        { field: 'visitStartedOn', title: 'Visit Start', cellStyle: { minWidth: '150px' } },
        { field: 'visitFinishedOn', title: 'Visit Finish', cellStyle: { minWidth: '150px' } },
    ]

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>Bank Tasks</h1>
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>

                            <div className="row mb-4">
                                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between'>
                                        <input
                                            type="text"
                                            placeholder="Search to display data..."
                                            className="block appearance-none w-50 bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm mr-4"
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />
                                    </div>
                                </div>

                                {searchQuery && (
                                    <MaterialTable
                                        icons={tableIcons}
                                        title={''}
                                        columns={headCells}
                                        data={paginatedData}
                                        options={{
                                            exportButton: false,
                                            search: false,
                                            exportAllData: false,
                                            rowStyle: {
                                                height: '5px',
                                                fontSize: 13,
                                            },
                                            paging: true,
                                            pageSize: 10,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [10],
                                            headerStyle: {
                                                position: 'sticky',
                                                top: '0',
                                            },
                                        }}
                                        onChangePage={(page) => setCurrentPage(page + 1)}
                                        onChangeRowsPerPage={() => {}} // We're not allowing changes to rows per page
                                        totalCount={filteredData.length}
                                    />
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default BankTask