export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL'
export const GET_ROLES_RESET = 'GET_ROLES_RESET'

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST'
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS'
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL'
export const ADD_ROLE_RESET = 'ADD_ROLE_RESET'

export const GET_ROLE_BY_ID_REQUEST = 'GET_ROLE_BY_ID_REQUEST'
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS'
export const GET_ROLE_BY_ID_FAIL = 'GET_ROLE_BY_ID_FAIL'
export const GET_ROLE_BY_ID_RESET = 'GET_ROLE_BY_ID_RESET'

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'
export const UPDATE_ROLE_RESET = 'UPDATE_ROLE_RESET'