import React, { useEffect, useState, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import moment from 'moment'

import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { getUsers } from '../actions/userActions';
import { assignPincode, getPincode, editPincode } from '../actions/pincodeActions';
import { GET_PINCODE_RESET, ASSIGN_PINCODE_RESET, EDIT_PINCODE_RESET } from '../constants/pincodeConstants'
import Input from '../components/Input'
import Button from '../components/Button'
import EditPincodeForm from '../screens/EditPincodeForm'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const PincodeScreen = ({ history }) => {
    const navigate = useNavigate();
    const [data, setData] = useState()
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [pincodeArray, setPincodeArray] = useState('');
    const [region, setRegion] = useState('');
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [addPincodeOption, setAddPincodeOption] = useState(true);
    const [loading, setLoading] = useState(true)
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editingPincode, setEditingPincode] = useState(null);

    const dispatch = useDispatch();
    const getUsersInfo = useSelector((state) => state.getUsersInfo);
    const { loading: loadingGetUsersInfo, error: errorGetUsersInfo, getUsersData } = getUsersInfo;

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    useEffect(() => {
        if (getUsersData) {
            setUsers(getUsersData.users);
        }
    }, [getUsersData]);

    useEffect(() => {
        dispatch(getPincode())
    }, [])

    // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      // history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.usersView) {
        toast('Not Authorized', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
        })
        navigate('/')
      }
    }
  }, [userInfo, getRoleData, history])

    // * Get Pincode
    const getPincodeInfo = useSelector((state) => state.getPincodeInfo)
    const { loadingGetPincodeInfo, errorGetPincodeInfo, getPincodeData } = getPincodeInfo

    useEffect(() => {
        dispatch({ type: GET_PINCODE_RESET })
        if (getPincodeData) {
            setLoading(false)
            setData(getPincodeData)
            console.log(getPincodeData)
        } else if (errorGetPincodeInfo) {
            setLoading(false)
            toast(errorGetPincodeInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getPincodeData, errorGetPincodeInfo])

    const handleSubmit = () => {
        if (!selectedUser || !pincodeArray || !region) {
            setError('Please select a user and enter pin codes and Region');
            return;
        }

        // Trim the pincode values and split the pincodeArray string into an array of pin codes
        const pincodes = pincodeArray.split(',').map(pincode => pincode.trim());

        // Dispatch assignPincode action with selected user and trimmed pin codes array
        dispatch(assignPincode(selectedUser, pincodes, region));
    };

    const addPincodeInfo = useSelector((state) => state.addPincodeInfo)
    const { loadingAddPincodeInfo, errorAddPincodeInfo, addPincodeData } =
        addPincodeInfo

    useEffect(() => {
        dispatch({ type: ASSIGN_PINCODE_RESET })
        if (addPincodeData) {
            toast('Pincode Assigned successfully', {
                type: 'success',
                hideProgressBar: true,
                autoClose: 2000,
            })
            setTimeout(() => {
                dispatch(getPincode())
                closePincodeModal()
            }, 1000)
        } else if (errorAddPincodeInfo) {
            toast(errorAddPincodeInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [addPincodeData, errorAddPincodeInfo])

    // * Users Table
    const headCells = [
        {
            field: 'userName',
            title: 'Name',
        },
        {
            field: 'pincodes',
            title: 'Pincodes',
        },
        {
            field: 'region',
            title: 'Region',
        },
        {
            field: 'createdAt',
            title: 'Created At',
            render: (rowData) =>
                moment(rowData.createdAt).format('DD-MM-YYYY HH:mm:ss'),
        },
        {
            field: 'actions',
            title: 'Actions',
            render: (rowData) => (
                <button onClick={() => handleEditClick(rowData)} className="text-blue-500 hover:text-blue-700">
                    <Edit />
                </button>
            ),
        },
    ]

    const editPincodeInfo = useSelector((state) => state.editPincodeInfo)
    const { loadingEditPincodeInfo, errorEditPincodeInfo, editPincodeData } = editPincodeInfo

    useEffect(() => {
        dispatch({ type: EDIT_PINCODE_RESET })
        if (editPincodeData) {
            toast('Pincode updated successfully', {
                type: 'success',
                hideProgressBar: true,
                autoClose: 2000,
            })
            setOpenEditModal(false)
            dispatch(getPincode())
        } else if (errorEditPincodeInfo) {
            toast(errorEditPincodeInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [editPincodeData, errorEditPincodeInfo])

    const handleEditClick = (rowData) => {
        setEditingPincode(rowData)
        setOpenEditModal(true)
    }

    const handleEditSubmit = (id, pincodes, region) => {
        dispatch(editPincode(id, pincodes, region))
    }
    

    // * Open Add User Modal
    const openAddPincodeModal = () => {
        setOpenModal(true)
    }

    // * Close Modal
    const closePincodeModal = () => {
        setOpenModal(false)
        setUsers('')
        setPincodeArray('')
        setRegion('')
        window.location.reload();
    }

    return (
        <div className='w-full h-full'>
            <h1 className='text-2xl font-semibold'>Pincodes Assignment</h1>
            <div className='bg-white shadow-md rounded px-8 py-4 my-4 j'>
                <div className='flex justify-between'>
                    <div className='flex gap-5'></div>
                    {addPincodeOption && (
                        <Button
                            custom='py-2'
                            type='button'
                            onClick={openAddPincodeModal}
                            text='Add a Pincode'
                        />
                    )}
                </div>
            </div>
            <MaterialTable
                icons={tableIcons}
                title={''}
                columns={headCells}
                data={data}
                options={{
                    exportButton: false,
                    search: true,
                    exportAllData: false,
                    rowStyle: {
                        height: '5px',
                        fontSize: 13,
                    },
                    paging: true,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 20, 50],
                    headerStyle: {
                        position: 'sticky',
                        top: '0',
                    },
                }}
            />
            {openModal && (
                <>
                    <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                            {/*content*/}
                            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                {/*header*/}
                                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                                    <h3 className='text-lg font-semibold'>Add Pincode</h3>
                                </div>
                                {/*body*/}
                                <div className='relative p-6 flex-auto'>
                                    <select
                                        className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                    >
                                        <option value="">Select User</option>
                                        {loadingGetUsersInfo ? (
                                            <option>Loading...</option>
                                        ) : (
                                            users.map((user) => (
                                                <option key={user._id} value={user._id}>{user.name}</option>
                                            ))
                                        )}
                                    </select>
                                    <input
                                        className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                                        type="text"
                                        placeholder="Enter pin codes (comma-separated)"
                                        value={pincodeArray}
                                        onChange={(e) => setPincodeArray(e.target.value)}
                                    />
                                    <input
                                        className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                                        type="text"
                                        placeholder="Enter Region"
                                        value={region}
                                        onChange={(e) => setRegion(e.target.value)}
                                    />
                                    <p className='text-sm mt-4'>
                                        All the fields with * are mandatory
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                                    <button
                                        className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                        type='button'
                                        onClick={closePincodeModal}
                                    >
                                        Close
                                    </button>

                                    <button
                                        className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                                        type='button'
                                        onClick={handleSubmit}
                                    // disabled={loadingAddPincodeInfo}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                </>
            )}
            {openEditModal && (
                <EditPincodeForm
                    pincode={editingPincode}
                    onClose={() => setOpenEditModal(false)}
                    onSubmit={handleEditSubmit}
                />
            )}
        </div>
    )
}


  

export default PincodeScreen