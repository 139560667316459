import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

import Login from './screens/Login';
import UserList from './screens/UserList';
import AddRole from './screens/addRole';
import Customers from './screens/Customers';
import Roles from './screens/Roles';
import SideBar from './components/SideBar';
import Container from './components/Container';
import TaskList from './screens/Task';
import Pincode from './screens/Pincode';
import TaskIdList from './screens/TaskId';
import TaskList1 from './screens/Task1';
import TaskById from './screens/taskById';
import Settings from './screens/Settings';
import ResignedUsers from './screens/ResignedUser';
import UnAssigned from './screens/UnAssignedTask';
import TransferredTask from './screens/TransferredTask';
import UserByID from './screens/UserById';
import Dashboard from './screens/Dashboard';
import TaskCount from './screens/TaskCount';
import RoleByID from './screens/RoleById';
import BankTask from './screens/BankTaskList';
import FieldExecutiveTasksScreen from './screens/FieldExecutiveTasks';
import FieldCount from './screens/FieldCountList';
import FieldHourTask from './screens/FieldHourTask';
import CompletedTask from './screens/CompletedTask';
import './assests/styles.css';
import './App.css';
import './assests/scripts'
import FinalisationTask from './screens/FinalisationTask';
import InProgressTask from './screens/InProgressTask';
import Video from './screens/Video';
import 'bootstrap/dist/css/bootstrap.min.css';
import Onemoth from './screens/oneMonthTask';

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<DefaultContainer />} />
      </Routes>
    </Router>
  );
}

const DefaultContainer = () => {
  return (
    <Container>
      <SideBar />
      <div className='mx-auto py-10 px-6 bg-gray-100 overflow-x-auto w-4/5'>
        <Routes>
          <Route path="/users" element={<UserList />} exact />
          <Route path="/customers" element={<Customers />} exact />
          <Route path="/roles" element={<Roles />} exact />
          <Route path='/roles/add' element={<AddRole />} exact />
          <Route path='/roles/update/:id' element={<RoleByID />} />
          <Route path="/tasks" element={<TaskList />} exact />
          <Route path='/finalisation' element={<FinalisationTask />} exact />
          <Route path='/completed' element={<CompletedTask />} exact />
          <Route path='/inprogress' element={<InProgressTask />} exact />
          <Route path="/collection" element={<TaskList1 />} exact />
          <Route path="/tasksid" element={<TaskIdList />} exact />
          <Route path="/pincodes" element={<Pincode />} exact />
          <Route path="/tasks/:id" element={<TaskById />} exact />
          <Route path='/settings' element={<Settings />} exact />
          <Route path='/unassigned' element={<UnAssigned />} exact />
          <Route path='/transferred' element={<TransferredTask />} exact />
          <Route path='/users/:id' element={<UserByID />} exact />
          <Route path='/video-call' element={ <Video />} exact />
          <Route path='/dashboard' element={ <Dashboard />} exact />
          <Route path='/taskcount' element={ <TaskCount />} exact />
          <Route path='/field-task' element={ <FieldExecutiveTasksScreen />} exact />
          <Route path='/resigned' element={ <ResignedUsers />} exact />
          <Route path='/banktasks' element={ <BankTask />} exact />
          <Route path='/fieldcount' element={ <FieldCount />} exact />
          <Route path='/fe-task' element={ <FieldHourTask />} exact />
          <Route path='/one-month' element={ <Onemoth />} exact />
        </Routes>
      </div>
    </Container>
  )
}

export default App;
